import DB from '@/helpers/indexedDb';
import { getCurrentTimestamp } from '@/helpers/time';
import { v4 as uuidv4 } from 'uuid';
import env from '@/helpers/env';
import { preloads } from '@/helpers/file';
import fetchProgress from 'fetch-progress';


async function prepareDownload(dispatch, downloads) {
  const localDatabase = await DB('sales-app');
  let databaseItem = null;
  const available = [];
  const iterate = async function (dispatch, downloads) {
    if (!downloads) {
      return [];
    }

    for (const download of downloads) {
      if (download.src) {
        available.push(download.src);
        databaseItem = download.src ? await localDatabase.getEntry(download.src) : null;
      }
      if (download.image) {
        available.push(download.image);
        databaseItem = download.image ? await localDatabase.getEntry(download.image) : null;
      }
      if ((!databaseItem || download.updated > databaseItem.updated) && download.src) {
        dispatch('addToQueue', download.src);
      }
      if ((!databaseItem || download.updated > databaseItem.updated) && download.image) {
        dispatch('addToQueue', download.image);
      }
      if (download.children && download.children.length > 0) {
        await iterate(dispatch, download.children);
      }
    }
  };
  await iterate(dispatch, downloads);
  return available;
}

export default {
  namespaced: true,
  state: {
    last_user: null,
    last_sync: null,
    queue: [],
    downloads: [],
    pause: false,
    offlineAvailable: [],
    offlineUploads: [],
    progressPercentPerFile: 0,
    progressCurrentFileName: '',
    showUpdateModal: false,
  },
  mutations: {
    REMOVE_FIRST_FROM_QUEUE(state) {
      state.queue.shift();
    },
    SET(state, { key, value }) {
      state[key] = value;
    },
    ADD_QUEUE(state, item) {
      if (item) {
        state.queue.push(item);
      }
    },
    SET_DOWNLOADS(state, downloads) {
      state.downloads = downloads;
    },
    SET_OFFLINE_AVAILABLE(state, available) {
      state.offlineAvailable = available;
    },
  },
  actions: {
    async startPause({ commit, dispatch }) {
      commit('SET', { key: 'pause', value: true });
      await dispatch('startLoading', 'sync.paused', { root: true });
    },
    async endPause({ commit, dispatch }) {
      commit('SET', { key: 'pause', value: false });
      await dispatch('endLoading', 'sync.paused', { root: true });
    },
    async togglePause({ state, commit, dispatch }) {
      commit('SET', { key: 'pause', value: !state.pause });
      if (state.pause) {
        await dispatch('startLoading', 'sync.paused', { root: true });
      } else {
        await dispatch('endLoading', 'sync.paused', { root: true });
      }
    },
    async addDownloads({ state, commit }, downloads) {
      const newDownload = [];
      const availableDownloads = state.downloads;

      downloads.forEach((download) => {
        const existingDownload = availableDownloads.find(item => download.id === item.id);

        if (!existingDownload) {
          newDownload.push(download);
        } else {
          availableDownloads[availableDownloads.findIndex(i => download.id === i.id)] = download;
        }
      });
      commit('SET_DOWNLOADS', [...newDownload, ...availableDownloads]);
    },
    async flush({ commit }) {
      commit('SET', { key: 'last_sync', value: null });
      commit('SET', { key: 'last_user', value: null });
      commit('SET', { key: 'queue', value: [] });
      const localDatabase = await DB('sales-app');
      localDatabase.deleteAll();
    },
    async endSync({ commit, dispatch, rootGetters }) {
      commit('SET', { key: 'last_sync', value: getCurrentTimestamp() });
      commit('SET', { key: 'last_user', value: rootGetters['auth/getCurrentUser'].id });
      await dispatch('endLoading', 'sync', { root: true });
    },
    async doUpdate({ dispatch }) {
      await dispatch('hideUpdateModal');
      await dispatch('endPause');
      await dispatch('triggerSync');
    },
    async skipUpdate({ dispatch }) {
      await dispatch('hideUpdateModal');
      await dispatch('endSync');
      await dispatch('startPause');
    },
    async triggerSync({ dispatch }) {
      await dispatch('sync');
      await dispatch('endSync');
    },
    async startSync({ state, commit, dispatch, rootGetters, rootState }, manual) {
      await dispatch('startLoading', 'sync', { root: true });
      if (manual && rootState.online) {
        await dispatch('endPause');
        await dispatch('fetchTessaDownloadInformation', null, { root: true });
        await dispatch('fetchTessaCategoriesAndProducts', null, { root: true });
        await dispatch('prepareQueue');
        await dispatch('triggerSync');
      } else {
        await dispatch('endPause');
        await dispatch('prepareQueue');
        if (state.queue.length > 0) {
          await dispatch('showUpdateModal');
        } else {
          await dispatch('endLoading', 'sync', { root: true });
        }
      }
    },
    async sync({ state, commit, dispatch }) {
      const localDatabase = await DB('sales-app');
      // is something in queue?
      if (state.queue.length <= 0) {
        dispatch('cleanUpStorage');
        return;
      }

      const queueId = state.queue[0]?.toLowerCase();

      // save blob in local database
      let blob = await fetch(queueId)
      .then(
          fetchProgress({
            onProgress(progress) {
              commit('SET', {
                key: 'progressCurrentFileName',
                value: queueId.substring(queueId.lastIndexOf('/') + 1),
              });
              commit('SET', { key: 'progressPercentPerFile', value: progress?.percentage || 0 });
            },
            onError(err) {
              console.log(err);
            },
          }),
      )
      .then(r => r.blob());

      if (blob.type === '' && queueId?.includes(env('cdn_tessa_image_channel'))) {
        blob = blob.slice(0, blob.size, 'image/png');
      }

      await localDatabase.put({
        id: queueId,
        blob: blob,
        updated: getCurrentTimestamp(),
      });

      // remove first entry
      commit('REMOVE_FIRST_FROM_QUEUE');

      // proceed if there is still something in the queue
      if (state.queue.length > 0 && state.pause === false) {
        await dispatch('sync');
      } else if (state.pause === false) {
        dispatch('cleanUpStorage');
      }
    },
    async addOfflineUpload({}, file) {
      const localDatabase = await DB('sales-app-offline-uploads');
      const id = uuidv4();

      await localDatabase.put({
        id,
        blob: file,
      });

      return id;
    },
    async removeOfflineUpload({}, fileId) {
      const localDatabase = await DB('sales-app-offline-uploads');
      const databaseItem = await localDatabase?.getEntry(fileId);
      if (databaseItem) {
        await localDatabase.deleteEntry(fileId);
        return true;
      } else {
        return false;
      }
    },
    async cleanUpOfflineUploads({ rootGetters }) {
      const localDatabase = await DB('sales-app-offline-uploads');
      const locallySavedLeads = rootGetters['leads/getLeadsFromCurrentUser'];
      const necessaryImages = [];

      for (const lead of locallySavedLeads) {
        if (lead.attachment1) necessaryImages.push(lead.attachment1);
        if (lead.attachment2) necessaryImages.push(lead.attachment2);
        if (lead.business_card_back) necessaryImages.push(lead.business_card_back);
        if (lead.business_card_front) necessaryImages.push(lead.business_card_front);
      }

      for (const entry of await localDatabase.getAll()) {
        if (!necessaryImages.includes(entry.id)) {
          await localDatabase.deleteEntry(entry.id);
        }
      }
    },
    addToQueue({ state, commit }, item) {
      if (!state.queue.includes(item)) {
        commit('ADD_QUEUE', item);
      }
    },
    async cleanUpStorage({ state }) {
      const localDatabase = await DB('sales-app');
      for (const entry of await localDatabase.getAll()) {
        if (!state.offlineAvailable.includes(entry.id)) {
          await localDatabase.deleteEntry(entry.id);
        }
      }

      // const iteratedItems = [];
      // state.items.forEach((item) => {
      //     const itemIndex = iteratedItems.findIndex(i => i.id === item.id);
      //
      //     if (itemIndex < 0) {
      //         iteratedItems.push(item);
      //     } else {
      //         if (iteratedItems[itemIndex].updated < item.updated) {
      //             iteratedItems[itemIndex] = item;
      //         }
      //     }
      // });
      //
      // await commit('SET', {key: 'items', value: iteratedItems});


    },
    async hideUpdateModal({ commit }) {
      await commit('SET', { key: 'showUpdateModal', value: false });
    },
    async showUpdateModal({ commit }) {
      await commit('SET', { key: 'showUpdateModal', value: true });
    },
    async prepareQueue({ state, rootGetters, getters, commit, dispatch }) {
      let available = [];
      const possiblePreloads = [];
      preloads.forEach((p) => {
        possiblePreloads.push({ src: `${process.env.BASE_URL}assets/${p}`, updated: 1675419151767 });
      });
      const drupalProducts = rootGetters['products/getProducts'];
      const products = rootGetters['products/getAllProducts'];
      const productCategories = rootGetters['productCategories/getAllItems'];
      const downloads = getters['getAllDownloads'];
      const categoryTree = rootGetters['mypoly/getCategoryGeneral'];
      const machineTree = rootGetters['mypoly/getCategoryMachines'];
      const availableDrupalProducts = await prepareDownload(dispatch, drupalProducts);
      const availableProducts = await prepareDownload(dispatch, products);
      const availableProductCategories = await prepareDownload(dispatch, productCategories);
      const availableDownloads = await prepareDownload(dispatch, downloads);
      const availableCategoryTree = await prepareDownload(dispatch, categoryTree);
      const availableMachineTree = await prepareDownload(dispatch, machineTree);
      const availableAssets = await prepareDownload(dispatch, possiblePreloads);

      available = [
        ...availableAssets,
        ...availableDrupalProducts,
        ...availableMachineTree,
        ...availableCategoryTree,
        ...availableProducts,
        ...availableProductCategories,
        ...availableDownloads,
      ];

      const queue = state.queue;
      const videos = [];

      queue.forEach((q) => {
        if (q.includes('.mp4') || q.includes('.wmv')) {
          videos.push(q);
          queue.splice(queue.indexOf(q), 1);
        }
      });

      commit('SET', { key: 'queue', value: [...queue, ...videos] });

      commit('SET_OFFLINE_AVAILABLE', available);
    },
  },
  getters: {
    getAllDownloads(state) {
      return state.downloads;
    },
    getDownloads: state => id => {
      return state.downloads.filter(download => download.attachmentOf.includes(id));
    },
    getDownloadsBySearch: state => (query) => {
      return state.downloads.filter(item => {
        return item.name.toLowerCase().includes(query.toLowerCase());
      });
    },
  },
};
